import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type CreatePruefungsergebnisMutationVariables = Types.Exact<{
  pruefungsergebnis?: Types.Maybe<Array<Types.Maybe<Types.PruefungsergebniCreateType>> | Types.Maybe<Types.PruefungsergebniCreateType>>;
}>;


export type CreatePruefungsergebnisMutation = { createPruefungsergebnis?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'Pruefungsergebni', id: number, note?: Types.Maybe<number>, punkte?: Types.Maybe<number>, effektivDatum?: Types.Maybe<string>, studentId?: Types.Maybe<number>, bemerkung?: Types.Maybe<string>, pruefungStatusId?: Types.Maybe<number>, pruefung?: Types.Maybe<{ __typename?: 'Pruefung', datumUhrzeitVon?: Types.Maybe<string>, id: number, pruefungsversuch?: Types.Maybe<number> }>, student?: Types.Maybe<{ __typename?: 'Student', anrede?: Types.Maybe<string>, nachname?: Types.Maybe<string>, vorname?: Types.Maybe<string> }>, pruefungStatus?: Types.Maybe<{ __typename?: 'PruefungStatus', id: number, pruefungStatusName?: Types.Maybe<string> }> }>>> };


export const CreatePruefungsergebnisDocument = gql`
    mutation createPruefungsergebnis($pruefungsergebnis: [PruefungsergebniCreateType]) {
  createPruefungsergebnis(pruefungsergebnis: $pruefungsergebnis) {
    id
    note
    punkte
    effektivDatum
    pruefung {
      datumUhrzeitVon
      id
      pruefungsversuch
    }
    studentId
    student {
      anrede
      nachname
      vorname
    }
    bemerkung
    pruefungStatus {
      id
      pruefungStatusName
    }
    pruefungStatusId
  }
}
    `;
export type CreatePruefungsergebnisMutationFn = ApolloReactCommon.MutationFunction<CreatePruefungsergebnisMutation, CreatePruefungsergebnisMutationVariables>;

/**
 * __useCreatePruefungsergebnisMutation__
 *
 * To run a mutation, you first call `useCreatePruefungsergebnisMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePruefungsergebnisMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPruefungsergebnisMutation, { data, loading, error }] = useCreatePruefungsergebnisMutation({
 *   variables: {
 *      pruefungsergebnis: // value for 'pruefungsergebnis'
 *   },
 * });
 */
export function useCreatePruefungsergebnisMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePruefungsergebnisMutation, CreatePruefungsergebnisMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePruefungsergebnisMutation, CreatePruefungsergebnisMutationVariables>(CreatePruefungsergebnisDocument, baseOptions);
      }
export type CreatePruefungsergebnisMutationHookResult = ReturnType<typeof useCreatePruefungsergebnisMutation>;
export type CreatePruefungsergebnisMutationResult = ApolloReactCommon.MutationResult<CreatePruefungsergebnisMutation>;
export type CreatePruefungsergebnisMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePruefungsergebnisMutation, CreatePruefungsergebnisMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
