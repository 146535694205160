import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetMatchStudiengangModulsQueryVariables = Types.Exact<{
  filterInput?: Types.Maybe<Types.MatchStudiengangModulFilterInput>;
}>;


export type GetMatchStudiengangModulsQuery = { matchStudiengangModuls: Array<{ __typename?: 'MatchStudiengangModul', id: number, studiengangId: number, modulId: number, planSemester?: Types.Maybe<number>, studiengang: { __typename?: 'Studiengang', id: number, studiengangName?: Types.Maybe<string> }, modul: { __typename?: 'Modul', id: number, modulName: string } }> };


export const GetMatchStudiengangModulsDocument = gql`
    query getMatchStudiengangModuls($filterInput: MatchStudiengangModulFilterInput) {
  matchStudiengangModuls(where: $filterInput) {
    id
    studiengangId
    studiengang {
      id
      studiengangName
    }
    modulId
    planSemester
    modul {
      id
      modulName
    }
  }
}
    `;

/**
 * __useGetMatchStudiengangModulsQuery__
 *
 * To run a query within a React component, call `useGetMatchStudiengangModulsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMatchStudiengangModulsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMatchStudiengangModulsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetMatchStudiengangModulsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMatchStudiengangModulsQuery, GetMatchStudiengangModulsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMatchStudiengangModulsQuery, GetMatchStudiengangModulsQueryVariables>(GetMatchStudiengangModulsDocument, baseOptions);
      }
export function useGetMatchStudiengangModulsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMatchStudiengangModulsQuery, GetMatchStudiengangModulsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMatchStudiengangModulsQuery, GetMatchStudiengangModulsQueryVariables>(GetMatchStudiengangModulsDocument, baseOptions);
        }
export type GetMatchStudiengangModulsQueryHookResult = ReturnType<typeof useGetMatchStudiengangModulsQuery>;
export type GetMatchStudiengangModulsLazyQueryHookResult = ReturnType<typeof useGetMatchStudiengangModulsLazyQuery>;
export type GetMatchStudiengangModulsQueryResult = ApolloReactCommon.QueryResult<GetMatchStudiengangModulsQuery, GetMatchStudiengangModulsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
