import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GtMyStudentDataQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GtMyStudentDataQuery = { myStudentData?: Types.Maybe<{ __typename?: 'MyStudentData', studentId: number, geschlechtTnid?: Types.Maybe<number>, titel?: Types.Maybe<string>, akademischerGradTnid?: Types.Maybe<number>, vorname?: Types.Maybe<string>, nachname?: Types.Maybe<string>, geburtsdatum?: Types.Maybe<string>, geburtsort?: Types.Maybe<string>, geburtslandTnid?: Types.Maybe<number>, staatsangehoerigkeitTnid?: Types.Maybe<number>, createdAt?: Types.Maybe<string>, wohnlandTnid?: Types.Maybe<number>, telefonnummer?: Types.Maybe<string>, emailPrivat?: Types.Maybe<string>, strasse?: Types.Maybe<string>, hausnummer?: Types.Maybe<string>, plz?: Types.Maybe<string>, wohnort?: Types.Maybe<string>, benutzername?: Types.Maybe<string>, emailKh?: Types.Maybe<string>, notizen?: Types.Maybe<string>, bemerkung?: Types.Maybe<string>, akademischerGrad?: Types.Maybe<string>, geburtsland?: Types.Maybe<string>, staatsangehoerigkeit?: Types.Maybe<string>, wohnland?: Types.Maybe<string> }> };


export const GtMyStudentDataDocument = gql`
    query gtMyStudentData {
  myStudentData {
    studentId
    geschlechtTnid
    titel
    akademischerGradTnid
    vorname
    nachname
    geburtsdatum
    geburtsort
    geburtslandTnid
    staatsangehoerigkeitTnid
    createdAt
    wohnlandTnid
    telefonnummer
    emailPrivat
    strasse
    hausnummer
    plz
    wohnort
    benutzername
    emailKh
    notizen
    bemerkung
    akademischerGrad
    geburtsland
    staatsangehoerigkeit
    wohnland
  }
}
    `;

/**
 * __useGtMyStudentDataQuery__
 *
 * To run a query within a React component, call `useGtMyStudentDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGtMyStudentDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGtMyStudentDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGtMyStudentDataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GtMyStudentDataQuery, GtMyStudentDataQueryVariables>) {
        return ApolloReactHooks.useQuery<GtMyStudentDataQuery, GtMyStudentDataQueryVariables>(GtMyStudentDataDocument, baseOptions);
      }
export function useGtMyStudentDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GtMyStudentDataQuery, GtMyStudentDataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GtMyStudentDataQuery, GtMyStudentDataQueryVariables>(GtMyStudentDataDocument, baseOptions);
        }
export type GtMyStudentDataQueryHookResult = ReturnType<typeof useGtMyStudentDataQuery>;
export type GtMyStudentDataLazyQueryHookResult = ReturnType<typeof useGtMyStudentDataLazyQuery>;
export type GtMyStudentDataQueryResult = ApolloReactCommon.QueryResult<GtMyStudentDataQuery, GtMyStudentDataQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
