import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type MatchStudiengangStudentCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.MatchStudiengangStudentInputCreate>
export type MatchStudiengangStudentCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.MatchStudiengangStudentInputCreate>
export type MatchStudiengangStudentCreateBeforeRemoteMuatation =
  BeforeRemoteMuatation<
    SchemaTypes.MatchStudiengangStudentInputCreate,
    SchemaTypes.MatchStudiengangStudentInputCreateProps["injectedValues"]
  >
export type MatchStudiengangStudentCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.MatchStudiengangStudentInputCreate,
    SchemaTypes.MatchStudiengangStudentInputCreateProps["injectedValues"]
  >
export type MatchStudiengangStudentCreateValidateForm = ValidateForm<
  SchemaTypes.MatchStudiengangStudentInputCreate,
  SchemaTypes.MatchStudiengangStudentInputCreateProps["injectedValues"]
>
export type MatchStudiengangStudentCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.MatchStudiengangStudentInputCreate,
  SchemaTypes.MatchStudiengangStudentInputCreateProps["injectedValues"]
>

export interface MatchStudiengangStudentCreateProps
  extends SchemaTypes.MatchStudiengangStudentInputCreateProps {
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.MatchStudiengangStudentInputCreate>
  afterCreate?: (
    data: SchemaTypes.MatchStudiengangStudentInputCreate & { id: number }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.MatchStudiengangStudentInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.MatchStudiengangStudentInputCreate,
    SchemaTypes.MatchStudiengangStudentInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.MatchStudiengangStudentInputCreate,
    SchemaTypes.MatchStudiengangStudentInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const MatchStudiengangStudentCreateForm: React.FC<MatchStudiengangStudentCreateProps> =
  (props) => {
    const {
        afterCreate,
        injectedValues,
        multiInstance = false,
        formVersion,
        onCancel,
        updateAfterCreateQueries,
        beforeRemoteMuatation,
        onUpdateDataMiddleWare,
        validateForm,
        modifyProps,
        ...params
      } = props,
      { Form: MatchStudiengangStudentCreate } = makeNewFormMemoized({
        entity: "matchStudiengangStudent",

        operation: "create",
        formVersion,
        params: multiInstance ? params : undefined,
      })
    return (
      <MatchStudiengangStudentCreate
        params={params}
        onCancel={onCancel}
        injectedValues={injectedValues}
        afterCreate={afterCreate}
        updateAfterCreateQueries={updateAfterCreateQueries}
        beforeRemoteMuatation={beforeRemoteMuatation}
        onUpdateDataMiddleWare={onUpdateDataMiddleWare}
        validateForm={validateForm}
        modifyProps={modifyProps}
      />
    )
  }

export type MatchStudiengangStudentUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.MatchStudiengangStudentInputUpdate>
export type MatchStudiengangStudentUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.MatchStudiengangStudentInputUpdate>
export type MatchStudiengangStudentUpdateBeforeRemoteMuatation =
  BeforeRemoteMuatation<
    SchemaTypes.MatchStudiengangStudentInputUpdate,
    SchemaTypes.MatchStudiengangStudentInputUpdateProps["injectedValues"]
  >
export type MatchStudiengangStudentUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.MatchStudiengangStudentInputUpdate,
    SchemaTypes.MatchStudiengangStudentInputUpdateProps["injectedValues"]
  >
export type MatchStudiengangStudentUpdateValidateForm = ValidateForm<
  SchemaTypes.MatchStudiengangStudentInputUpdate,
  SchemaTypes.MatchStudiengangStudentInputUpdateProps["injectedValues"]
>
export type MatchStudiengangStudentUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.MatchStudiengangStudentInputUpdate,
  SchemaTypes.MatchStudiengangStudentInputUpdateProps["injectedValues"]
>

export interface MatchStudiengangStudentUpdateProps
  extends SchemaTypes.MatchStudiengangStudentInputUpdateProps {
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.MatchStudiengangStudentInputUpdate>
  afterUpdate?: (data: SchemaTypes.MatchStudiengangStudentInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.MatchStudiengangStudentInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.MatchStudiengangStudentInputUpdate,
    SchemaTypes.MatchStudiengangStudentInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.MatchStudiengangStudentInputUpdate,
    SchemaTypes.MatchStudiengangStudentInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const MatchStudiengangStudentUpdateForm: React.FC<MatchStudiengangStudentUpdateProps> =
  (props) => {
    const {
        afterUpdate,
        injectedValues,
        multiInstance = false,
        formVersion,
        onCancel,
        fetchAllFieldsOnUpdate,
        beforeRemoteMuatation,
        onUpdateDataMiddleWare,
        validateForm,
        modifyProps,
        ...params
      } = props,
      { Form: MatchStudiengangStudentUpdate } = makeNewFormMemoized({
        entity: "matchStudiengangStudent",

        operation: "update",
        formVersion,
        params: multiInstance ? params : undefined,
      })
    return (
      <MatchStudiengangStudentUpdate
        params={params}
        onCancel={onCancel}
        injectedValues={injectedValues}
        afterUpdate={afterUpdate}
        fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
        beforeRemoteMuatation={beforeRemoteMuatation}
        onUpdateDataMiddleWare={onUpdateDataMiddleWare}
        validateForm={validateForm}
        modifyProps={modifyProps}
      />
    )
  }
