import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type ModulterminCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.ModulterminInputCreate>
export type ModulterminCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.ModulterminInputCreate>
export type ModulterminCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.ModulterminInputCreate,
  SchemaTypes.ModulterminInputCreateProps["injectedValues"]
>
export type ModulterminCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.ModulterminInputCreate,
    SchemaTypes.ModulterminInputCreateProps["injectedValues"]
  >
export type ModulterminCreateValidateForm = ValidateForm<
  SchemaTypes.ModulterminInputCreate,
  SchemaTypes.ModulterminInputCreateProps["injectedValues"]
>
export type ModulterminCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.ModulterminInputCreate,
  SchemaTypes.ModulterminInputCreateProps["injectedValues"]
>

export interface ModulterminCreateProps
  extends SchemaTypes.ModulterminInputCreateProps {
  moodleModulId: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.ModulterminInputCreate>
  afterCreate?: (
    data: SchemaTypes.ModulterminInputCreate & { id: number }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.ModulterminInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.ModulterminInputCreate,
    SchemaTypes.ModulterminInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.ModulterminInputCreate,
    SchemaTypes.ModulterminInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const ModulterminCreateForm: React.FC<ModulterminCreateProps> = (
  props
) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: ModulterminCreate } = makeNewFormMemoized({
      entity: "modultermin",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <ModulterminCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type ModulterminUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.ModulterminInputUpdate>
export type ModulterminUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.ModulterminInputUpdate>
export type ModulterminUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.ModulterminInputUpdate,
  SchemaTypes.ModulterminInputUpdateProps["injectedValues"]
>
export type ModulterminUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.ModulterminInputUpdate,
    SchemaTypes.ModulterminInputUpdateProps["injectedValues"]
  >
export type ModulterminUpdateValidateForm = ValidateForm<
  SchemaTypes.ModulterminInputUpdate,
  SchemaTypes.ModulterminInputUpdateProps["injectedValues"]
>
export type ModulterminUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.ModulterminInputUpdate,
  SchemaTypes.ModulterminInputUpdateProps["injectedValues"]
>

export interface ModulterminUpdateProps
  extends SchemaTypes.ModulterminInputUpdateProps {
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.ModulterminInputUpdate>
  afterUpdate?: (data: SchemaTypes.ModulterminInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.ModulterminInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.ModulterminInputUpdate,
    SchemaTypes.ModulterminInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.ModulterminInputUpdate,
    SchemaTypes.ModulterminInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const ModulterminUpdateForm: React.FC<ModulterminUpdateProps> = (
  props
) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: ModulterminUpdate } = makeNewFormMemoized({
      entity: "modultermin",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <ModulterminUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
