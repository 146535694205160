import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetMyStudentGradeOverviewQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetMyStudentGradeOverviewQuery = { myStudentGradeOverview?: Types.Maybe<{ __typename?: 'StudentGradeOverview', grade?: Types.Maybe<number>, eCTS?: Types.Maybe<number>, currentSemester?: Types.Maybe<string>, modules?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'ModuleGrade', modulId: number, semester?: Types.Maybe<number>, modulbezeichnung?: Types.Maybe<string>, eCTS?: Types.Maybe<number>, pruefungsId?: Types.Maybe<number>, pruefungsform?: Types.Maybe<string>, grade?: Types.Maybe<number>, points?: Types.Maybe<number>, note?: Types.Maybe<number>, color?: Types.Maybe<string>, examStatus?: Types.Maybe<string>, eCTSString?: Types.Maybe<string> }>>>, student?: Types.Maybe<{ __typename?: 'Student', id: number, geschlechtTnid?: Types.Maybe<number>, titel?: Types.Maybe<string>, akademischerGradTnid?: Types.Maybe<number>, vorname?: Types.Maybe<string>, nachname?: Types.Maybe<string>, geburtsdatum?: Types.Maybe<string>, geburtsort?: Types.Maybe<string>, geburtslandTnid?: Types.Maybe<number>, staatsangehoerigkeitTnid?: Types.Maybe<number>, createdAt?: Types.Maybe<string>, wohnlandTnid?: Types.Maybe<number>, telefonnummer?: Types.Maybe<string>, emailPrivat?: Types.Maybe<string>, strasse?: Types.Maybe<string>, hausnummer?: Types.Maybe<string>, plz?: Types.Maybe<string>, wohnort?: Types.Maybe<string>, benutzername?: Types.Maybe<string>, emailKh?: Types.Maybe<string>, notizen?: Types.Maybe<string>, bemerkung?: Types.Maybe<string> }> }> };


export const GetMyStudentGradeOverviewDocument = gql`
    query getMyStudentGradeOverview {
  myStudentGradeOverview {
    modules {
      modulId
      semester
      modulbezeichnung
      eCTS
      pruefungsId
      pruefungsform
      grade
      points
      note
      color
      examStatus
      eCTSString
    }
    grade
    eCTS
    student {
      id
      id
      geschlechtTnid
      titel
      akademischerGradTnid
      vorname
      nachname
      geburtsdatum
      geburtsort
      geburtslandTnid
      staatsangehoerigkeitTnid
      createdAt
      wohnlandTnid
      telefonnummer
      emailPrivat
      strasse
      hausnummer
      plz
      wohnort
      benutzername
      emailKh
      notizen
      bemerkung
    }
    currentSemester
  }
}
    `;

/**
 * __useGetMyStudentGradeOverviewQuery__
 *
 * To run a query within a React component, call `useGetMyStudentGradeOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyStudentGradeOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyStudentGradeOverviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyStudentGradeOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyStudentGradeOverviewQuery, GetMyStudentGradeOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMyStudentGradeOverviewQuery, GetMyStudentGradeOverviewQueryVariables>(GetMyStudentGradeOverviewDocument, baseOptions);
      }
export function useGetMyStudentGradeOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyStudentGradeOverviewQuery, GetMyStudentGradeOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMyStudentGradeOverviewQuery, GetMyStudentGradeOverviewQueryVariables>(GetMyStudentGradeOverviewDocument, baseOptions);
        }
export type GetMyStudentGradeOverviewQueryHookResult = ReturnType<typeof useGetMyStudentGradeOverviewQuery>;
export type GetMyStudentGradeOverviewLazyQueryHookResult = ReturnType<typeof useGetMyStudentGradeOverviewLazyQuery>;
export type GetMyStudentGradeOverviewQueryResult = ApolloReactCommon.QueryResult<GetMyStudentGradeOverviewQuery, GetMyStudentGradeOverviewQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
