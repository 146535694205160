import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type MoodleSyncStudentsMutationVariables = Types.Exact<{
  moodleCourseId: Types.Scalars['Int'];
}>;


export type MoodleSyncStudentsMutation = { moodleSync?: Types.Maybe<{ __typename?: 'MoodleSyncResult', status: boolean, statusCode?: Types.Maybe<string>, statusMessage?: Types.Maybe<string> }> };


export const MoodleSyncStudentsDocument = gql`
    mutation moodleSyncStudents($moodleCourseId: Int!) {
  moodleSync: moodleSyncStudents(moodleCourseId: $moodleCourseId) {
    status
    statusCode
    statusMessage
  }
}
    `;
export type MoodleSyncStudentsMutationFn = ApolloReactCommon.MutationFunction<MoodleSyncStudentsMutation, MoodleSyncStudentsMutationVariables>;

/**
 * __useMoodleSyncStudentsMutation__
 *
 * To run a mutation, you first call `useMoodleSyncStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoodleSyncStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moodleSyncStudentsMutation, { data, loading, error }] = useMoodleSyncStudentsMutation({
 *   variables: {
 *      moodleCourseId: // value for 'moodleCourseId'
 *   },
 * });
 */
export function useMoodleSyncStudentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoodleSyncStudentsMutation, MoodleSyncStudentsMutationVariables>) {
        return ApolloReactHooks.useMutation<MoodleSyncStudentsMutation, MoodleSyncStudentsMutationVariables>(MoodleSyncStudentsDocument, baseOptions);
      }
export type MoodleSyncStudentsMutationHookResult = ReturnType<typeof useMoodleSyncStudentsMutation>;
export type MoodleSyncStudentsMutationResult = ApolloReactCommon.MutationResult<MoodleSyncStudentsMutation>;
export type MoodleSyncStudentsMutationOptions = ApolloReactCommon.BaseMutationOptions<MoodleSyncStudentsMutation, MoodleSyncStudentsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
