import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type AddMatchMoodleModulStudentsMutationVariables = Types.Exact<{
  matchMoodleModulStudents?: Types.Maybe<Array<Types.Maybe<Types.MatchMoodleModulStudentCreateType>> | Types.Maybe<Types.MatchMoodleModulStudentCreateType>>;
}>;


export type AddMatchMoodleModulStudentsMutation = { createMatchMoodleModulStudents?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'MatchMoodleModulStudent', id: number, studentId?: Types.Maybe<number>, moodleModulId?: Types.Maybe<number> }>>> };


export const AddMatchMoodleModulStudentsDocument = gql`
    mutation addMatchMoodleModulStudents($matchMoodleModulStudents: [MatchMoodleModulStudentCreateType]) {
  createMatchMoodleModulStudents(matchMoodleModulStudents: $matchMoodleModulStudents) {
    id
    studentId
    moodleModulId
  }
}
    `;
export type AddMatchMoodleModulStudentsMutationFn = ApolloReactCommon.MutationFunction<AddMatchMoodleModulStudentsMutation, AddMatchMoodleModulStudentsMutationVariables>;

/**
 * __useAddMatchMoodleModulStudentsMutation__
 *
 * To run a mutation, you first call `useAddMatchMoodleModulStudentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMatchMoodleModulStudentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMatchMoodleModulStudentsMutation, { data, loading, error }] = useAddMatchMoodleModulStudentsMutation({
 *   variables: {
 *      matchMoodleModulStudents: // value for 'matchMoodleModulStudents'
 *   },
 * });
 */
export function useAddMatchMoodleModulStudentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMatchMoodleModulStudentsMutation, AddMatchMoodleModulStudentsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMatchMoodleModulStudentsMutation, AddMatchMoodleModulStudentsMutationVariables>(AddMatchMoodleModulStudentsDocument, baseOptions);
      }
export type AddMatchMoodleModulStudentsMutationHookResult = ReturnType<typeof useAddMatchMoodleModulStudentsMutation>;
export type AddMatchMoodleModulStudentsMutationResult = ApolloReactCommon.MutationResult<AddMatchMoodleModulStudentsMutation>;
export type AddMatchMoodleModulStudentsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMatchMoodleModulStudentsMutation, AddMatchMoodleModulStudentsMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
