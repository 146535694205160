import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetModulTermineQueryVariables = Types.Exact<{
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  where?: Types.Maybe<Types.ModulterminFilterInput>;
  order?: Types.Maybe<Array<Types.ModulterminSortInput> | Types.ModulterminSortInput>;
}>;


export type GetModulTermineQuery = { modultermins: Array<{ __typename?: 'Modultermin', id: number, modulterminTypId?: Types.Maybe<number>, datumVon?: Types.Maybe<string>, datumBis?: Types.Maybe<string>, dauer?: Types.Maybe<number>, modulterminTyp?: Types.Maybe<{ __typename?: 'ModulterminTyp', id: number, modulterminTypName?: Types.Maybe<string> }>, moodleModul?: Types.Maybe<{ __typename?: 'MoodleModul', modul?: Types.Maybe<{ __typename?: 'Modul', id: number, modulName: string }>, modulVerantwortlicher?: Types.Maybe<{ __typename?: 'StammdatenMa', id: number, vorname: string, nachname: string, emailKh: string }>, zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', id: number, zeitraumName: string }> }> }> };


export const GetModulTermineDocument = gql`
    query getModulTermine($skip: Int, $take: Int, $where: ModulterminFilterInput, $order: [ModulterminSortInput!]) {
  modultermins(where: $where, order: $order, skip: $skip, take: $take) {
    id
    modulterminTypId
    datumVon
    datumBis
    dauer
    modulterminTyp {
      id
      modulterminTypName
    }
    moodleModul {
      modul {
        id
        modulName
      }
      modulVerantwortlicher {
        id
        vorname
        nachname
        emailKh
      }
      zeitraum {
        id
        zeitraumName
      }
    }
  }
}
    `;

/**
 * __useGetModulTermineQuery__
 *
 * To run a query within a React component, call `useGetModulTermineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulTermineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulTermineQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetModulTermineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetModulTermineQuery, GetModulTermineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetModulTermineQuery, GetModulTermineQueryVariables>(GetModulTermineDocument, baseOptions);
      }
export function useGetModulTermineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetModulTermineQuery, GetModulTermineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetModulTermineQuery, GetModulTermineQueryVariables>(GetModulTermineDocument, baseOptions);
        }
export type GetModulTermineQueryHookResult = ReturnType<typeof useGetModulTermineQuery>;
export type GetModulTermineLazyQueryHookResult = ReturnType<typeof useGetModulTermineLazyQuery>;
export type GetModulTermineQueryResult = ApolloReactCommon.QueryResult<GetModulTermineQuery, GetModulTermineQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
