import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type PruefungCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.PruefungInputCreate>
export type PruefungCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.PruefungInputCreate>
export type PruefungCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.PruefungInputCreate,
  SchemaTypes.PruefungInputCreateProps["injectedValues"]
>
export type PruefungCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.PruefungInputCreate,
    SchemaTypes.PruefungInputCreateProps["injectedValues"]
  >
export type PruefungCreateValidateForm = ValidateForm<
  SchemaTypes.PruefungInputCreate,
  SchemaTypes.PruefungInputCreateProps["injectedValues"]
>
export type PruefungCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.PruefungInputCreate,
  SchemaTypes.PruefungInputCreateProps["injectedValues"]
>

export interface PruefungCreateProps
  extends SchemaTypes.PruefungInputCreateProps {
  modulId: number
  moodleModulId: number
  zeitraumId: number
  pruefungsdauer: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.PruefungInputCreate>
  afterCreate?: (data: SchemaTypes.PruefungInputCreate & { id: number }) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.PruefungInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.PruefungInputCreate,
    SchemaTypes.PruefungInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.PruefungInputCreate,
    SchemaTypes.PruefungInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const PruefungCreateForm: React.FC<PruefungCreateProps> = (props) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: PruefungCreate } = makeNewFormMemoized({
      entity: "pruefung",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <PruefungCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type PruefungUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.PruefungInputUpdate>
export type PruefungUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.PruefungInputUpdate>
export type PruefungUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.PruefungInputUpdate,
  SchemaTypes.PruefungInputUpdateProps["injectedValues"]
>
export type PruefungUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.PruefungInputUpdate,
    SchemaTypes.PruefungInputUpdateProps["injectedValues"]
  >
export type PruefungUpdateValidateForm = ValidateForm<
  SchemaTypes.PruefungInputUpdate,
  SchemaTypes.PruefungInputUpdateProps["injectedValues"]
>
export type PruefungUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.PruefungInputUpdate,
  SchemaTypes.PruefungInputUpdateProps["injectedValues"]
>

export interface PruefungUpdateProps
  extends SchemaTypes.PruefungInputUpdateProps {
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.PruefungInputUpdate>
  afterUpdate?: (data: SchemaTypes.PruefungInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.PruefungInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.PruefungInputUpdate,
    SchemaTypes.PruefungInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.PruefungInputUpdate,
    SchemaTypes.PruefungInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const PruefungUpdateForm: React.FC<PruefungUpdateProps> = (props) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: PruefungUpdate } = makeNewFormMemoized({
      entity: "pruefung",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <PruefungUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
