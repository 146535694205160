import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetVeranstaltungbyIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetVeranstaltungbyIdQuery = { veranstaltung?: Types.Maybe<(
    { __typename?: 'Veranstaltung' }
    & VeranstaltungFragment
  )> };

export type VeranstaltungFragment = { __typename?: 'Veranstaltung', id: number, veranstaltungName: string, datum: string, veranstaltungBeschreibung?: Types.Maybe<string>, veranstaltungEmailFeldText?: Types.Maybe<string>, veranstaltungHandynummerFeldText?: Types.Maybe<string>, veranstaltungCheckboxFeldText?: Types.Maybe<string>, veranstaltungBestaetigungFeldText?: Types.Maybe<string> };

export const VeranstaltungFragmentDoc = gql`
    fragment veranstaltung on Veranstaltung {
  id
  veranstaltungName
  datum
  datum
  veranstaltungBeschreibung
  veranstaltungEmailFeldText
  veranstaltungHandynummerFeldText
  veranstaltungCheckboxFeldText
  veranstaltungBestaetigungFeldText
}
    `;
export const GetVeranstaltungbyIdDocument = gql`
    query getVeranstaltungbyId($id: Int!) {
  veranstaltung(id: $id) {
    ...veranstaltung
  }
}
    ${VeranstaltungFragmentDoc}`;

/**
 * __useGetVeranstaltungbyIdQuery__
 *
 * To run a query within a React component, call `useGetVeranstaltungbyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVeranstaltungbyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVeranstaltungbyIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetVeranstaltungbyIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVeranstaltungbyIdQuery, GetVeranstaltungbyIdQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVeranstaltungbyIdQuery, GetVeranstaltungbyIdQueryVariables>(GetVeranstaltungbyIdDocument, baseOptions);
      }
export function useGetVeranstaltungbyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVeranstaltungbyIdQuery, GetVeranstaltungbyIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVeranstaltungbyIdQuery, GetVeranstaltungbyIdQueryVariables>(GetVeranstaltungbyIdDocument, baseOptions);
        }
export type GetVeranstaltungbyIdQueryHookResult = ReturnType<typeof useGetVeranstaltungbyIdQuery>;
export type GetVeranstaltungbyIdLazyQueryHookResult = ReturnType<typeof useGetVeranstaltungbyIdLazyQuery>;
export type GetVeranstaltungbyIdQueryResult = ApolloReactCommon.QueryResult<GetVeranstaltungbyIdQuery, GetVeranstaltungbyIdQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
