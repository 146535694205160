import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type StudiengangCreateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.StudiengangInputCreate>
export type StudiengangCreateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.StudiengangInputCreate>
export type StudiengangCreateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.StudiengangInputCreate,
  SchemaTypes.StudiengangInputCreateProps["injectedValues"]
>
export type StudiengangCreateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.StudiengangInputCreate,
    SchemaTypes.StudiengangInputCreateProps["injectedValues"]
  >
export type StudiengangCreateValidateForm = ValidateForm<
  SchemaTypes.StudiengangInputCreate,
  SchemaTypes.StudiengangInputCreateProps["injectedValues"]
>
export type StudiengangCreateValidateFormProps = ValidateFormProps<
  SchemaTypes.StudiengangInputCreate,
  SchemaTypes.StudiengangInputCreateProps["injectedValues"]
>

export interface StudiengangCreateProps
  extends SchemaTypes.StudiengangInputCreateProps {
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.StudiengangInputCreate>
  afterCreate?: (
    data: SchemaTypes.StudiengangInputCreate & { id: number }
  ) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.StudiengangInputCreate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.StudiengangInputCreate,
    SchemaTypes.StudiengangInputCreateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.StudiengangInputCreate,
    SchemaTypes.StudiengangInputCreateProps["injectedValues"]
  >
  updateAfterCreateQueries?: QueryArray
}

export const StudiengangCreateForm: React.FC<StudiengangCreateProps> = (
  props
) => {
  const {
      afterCreate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      updateAfterCreateQueries,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: StudiengangCreate } = makeNewFormMemoized({
      entity: "studiengang",

      operation: "create",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <StudiengangCreate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterCreate={afterCreate}
      updateAfterCreateQueries={updateAfterCreateQueries}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}

export type StudiengangUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.StudiengangInputUpdate>
export type StudiengangUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.StudiengangInputUpdate>
export type StudiengangUpdateBeforeRemoteMuatation = BeforeRemoteMuatation<
  SchemaTypes.StudiengangInputUpdate,
  SchemaTypes.StudiengangInputUpdateProps["injectedValues"]
>
export type StudiengangUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.StudiengangInputUpdate,
    SchemaTypes.StudiengangInputUpdateProps["injectedValues"]
  >
export type StudiengangUpdateValidateForm = ValidateForm<
  SchemaTypes.StudiengangInputUpdate,
  SchemaTypes.StudiengangInputUpdateProps["injectedValues"]
>
export type StudiengangUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.StudiengangInputUpdate,
  SchemaTypes.StudiengangInputUpdateProps["injectedValues"]
>

export interface StudiengangUpdateProps
  extends SchemaTypes.StudiengangInputUpdateProps {
  ausbildungsArtId: number
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.StudiengangInputUpdate>
  afterUpdate?: (data: SchemaTypes.StudiengangInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.StudiengangInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.StudiengangInputUpdate,
    SchemaTypes.StudiengangInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.StudiengangInputUpdate,
    SchemaTypes.StudiengangInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const StudiengangUpdateForm: React.FC<StudiengangUpdateProps> = (
  props
) => {
  const {
      afterUpdate,
      injectedValues,
      multiInstance = false,
      formVersion,
      onCancel,
      fetchAllFieldsOnUpdate,
      beforeRemoteMuatation,
      onUpdateDataMiddleWare,
      validateForm,
      modifyProps,
      ...params
    } = props,
    { Form: StudiengangUpdate } = makeNewFormMemoized({
      entity: "studiengang",

      operation: "update",
      formVersion,
      params: multiInstance ? params : undefined,
    })
  return (
    <StudiengangUpdate
      params={params}
      onCancel={onCancel}
      injectedValues={injectedValues}
      afterUpdate={afterUpdate}
      fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
      beforeRemoteMuatation={beforeRemoteMuatation}
      onUpdateDataMiddleWare={onUpdateDataMiddleWare}
      validateForm={validateForm}
      modifyProps={modifyProps}
    />
  )
}
