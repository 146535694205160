import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type SendApplicationMutationVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type SendApplicationMutation = { sendApplication?: Types.Maybe<{ __typename?: 'ConfirmApplication', status: boolean, statusMessage?: Types.Maybe<string> }> };


export const SendApplicationDocument = gql`
    mutation sendApplication($id: Int!) {
  sendApplication(bewerberId: $id) {
    status
    statusMessage
  }
}
    `;
export type SendApplicationMutationFn = ApolloReactCommon.MutationFunction<SendApplicationMutation, SendApplicationMutationVariables>;

/**
 * __useSendApplicationMutation__
 *
 * To run a mutation, you first call `useSendApplicationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendApplicationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendApplicationMutation, { data, loading, error }] = useSendApplicationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendApplicationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendApplicationMutation, SendApplicationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendApplicationMutation, SendApplicationMutationVariables>(SendApplicationDocument, baseOptions);
      }
export type SendApplicationMutationHookResult = ReturnType<typeof useSendApplicationMutation>;
export type SendApplicationMutationResult = ApolloReactCommon.MutationResult<SendApplicationMutation>;
export type SendApplicationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendApplicationMutation, SendApplicationMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
