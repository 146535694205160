import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetCiandoTokenQueryVariables = Types.Exact<{
  token: Types.Scalars['String'];
}>;


export type GetCiandoTokenQuery = { generateCiandoJWTToken?: Types.Maybe<{ __typename?: 'GenerateToken', status: boolean, statusMessage?: Types.Maybe<string>, token?: Types.Maybe<string> }> };


export const GetCiandoTokenDocument = gql`
    query getCiandoToken($token: String!) {
  generateCiandoJWTToken(jwtToken: $token) {
    status
    statusMessage
    token
  }
}
    `;

/**
 * __useGetCiandoTokenQuery__
 *
 * To run a query within a React component, call `useGetCiandoTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCiandoTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCiandoTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useGetCiandoTokenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCiandoTokenQuery, GetCiandoTokenQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCiandoTokenQuery, GetCiandoTokenQueryVariables>(GetCiandoTokenDocument, baseOptions);
      }
export function useGetCiandoTokenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCiandoTokenQuery, GetCiandoTokenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCiandoTokenQuery, GetCiandoTokenQueryVariables>(GetCiandoTokenDocument, baseOptions);
        }
export type GetCiandoTokenQueryHookResult = ReturnType<typeof useGetCiandoTokenQuery>;
export type GetCiandoTokenLazyQueryHookResult = ReturnType<typeof useGetCiandoTokenLazyQuery>;
export type GetCiandoTokenQueryResult = ApolloReactCommon.QueryResult<GetCiandoTokenQuery, GetCiandoTokenQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
