import {
  makeNewFormMemoized,
  genericDeleteFunction as genericDeleteFunctionPre,
} from "./"
import {
  Operation,
  QueryArray,
  DeleteChildProps,
  BeforeRemoteMuatation,
  BeforeRemoteMuatationProps,
  OnUpdateDataMiddleWare,
  OnUpdateDataMiddleWareProps,
  ValidateForm,
  ValidateFormProps,
} from "@ml-pa/react-apollo-form-tool/formGen.model"
import React from "react"
import * as SchemaTypes from "./internal/schemaTypes"
import { FormTypes, ModifyProps } from "./internal/typeLinks"

export type MatchModulStudentUpdateOnUpdateDataMiddleWare =
  OnUpdateDataMiddleWare<SchemaTypes.MatchModulStudentInputUpdate>
export type MatchModulStudentUpdateOnUpdateDataMiddleWareProps =
  OnUpdateDataMiddleWareProps<SchemaTypes.MatchModulStudentInputUpdate>
export type MatchModulStudentUpdateBeforeRemoteMuatation =
  BeforeRemoteMuatation<
    SchemaTypes.MatchModulStudentInputUpdate,
    SchemaTypes.MatchModulStudentInputUpdateProps["injectedValues"]
  >
export type MatchModulStudentUpdateBeforeRemoteMuatationProps =
  BeforeRemoteMuatationProps<
    SchemaTypes.MatchModulStudentInputUpdate,
    SchemaTypes.MatchModulStudentInputUpdateProps["injectedValues"]
  >
export type MatchModulStudentUpdateValidateForm = ValidateForm<
  SchemaTypes.MatchModulStudentInputUpdate,
  SchemaTypes.MatchModulStudentInputUpdateProps["injectedValues"]
>
export type MatchModulStudentUpdateValidateFormProps = ValidateFormProps<
  SchemaTypes.MatchModulStudentInputUpdate,
  SchemaTypes.MatchModulStudentInputUpdateProps["injectedValues"]
>

export interface MatchModulStudentUpdateProps
  extends SchemaTypes.MatchModulStudentInputUpdateProps {
  id: number
  multiInstance?: boolean
  formVersion?: string
  onCancel?: () => void
  modifyProps?: ModifyProps<SchemaTypes.MatchModulStudentInputUpdate>
  afterUpdate?: (data: SchemaTypes.MatchModulStudentInputUpdate) => void
  onUpdateDataMiddleWare?: OnUpdateDataMiddleWare<SchemaTypes.MatchModulStudentInputUpdate>
  beforeRemoteMuatation?: BeforeRemoteMuatation<
    SchemaTypes.MatchModulStudentInputUpdate,
    SchemaTypes.MatchModulStudentInputUpdateProps["injectedValues"]
  >
  validateForm?: ValidateForm<
    SchemaTypes.MatchModulStudentInputUpdate,
    SchemaTypes.MatchModulStudentInputUpdateProps["injectedValues"]
  >
  fetchAllFieldsOnUpdate?: boolean
}

export const MatchModulStudentUpdateForm: React.FC<MatchModulStudentUpdateProps> =
  (props) => {
    const {
        afterUpdate,
        injectedValues,
        multiInstance = false,
        formVersion,
        onCancel,
        fetchAllFieldsOnUpdate,
        beforeRemoteMuatation,
        onUpdateDataMiddleWare,
        validateForm,
        modifyProps,
        ...params
      } = props,
      { Form: MatchModulStudentUpdate } = makeNewFormMemoized({
        entity: "matchModulStudent",

        operation: "update",
        formVersion,
        params: multiInstance ? params : undefined,
      })
    return (
      <MatchModulStudentUpdate
        params={params}
        onCancel={onCancel}
        injectedValues={injectedValues}
        afterUpdate={afterUpdate}
        fetchAllFieldsOnUpdate={fetchAllFieldsOnUpdate}
        beforeRemoteMuatation={beforeRemoteMuatation}
        onUpdateDataMiddleWare={onUpdateDataMiddleWare}
        validateForm={validateForm}
        modifyProps={modifyProps}
      />
    )
  }
