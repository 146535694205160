import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetPruefungsTypenForPruefungQueryVariables = Types.Exact<{
  modulId: Types.Scalars['Int'];
}>;


export type GetPruefungsTypenForPruefungQuery = { pruefungTyps: Array<{ __typename?: 'PruefungTyp', id: number, pruefungTypName?: Types.Maybe<string>, matchModulPruefungTyps: Array<{ __typename?: 'MatchModulPruefungTyp', id: number, dauer?: Types.Maybe<number>, modulId?: Types.Maybe<number>, anteil?: Types.Maybe<number> }> }> };


export const GetPruefungsTypenForPruefungDocument = gql`
    query getPruefungsTypenForPruefung($modulId: Int!) {
  pruefungTyps(where: {matchModulPruefungTyps: {some: {isDeleted: {eq: false}, modulId: {eq: $modulId}}}}) {
    id
    pruefungTypName
    matchModulPruefungTyps(where: {modulId: {eq: $modulId}, isDeleted: {neq: true}}) {
      id
      dauer
      modulId
      anteil
    }
  }
}
    `;

/**
 * __useGetPruefungsTypenForPruefungQuery__
 *
 * To run a query within a React component, call `useGetPruefungsTypenForPruefungQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPruefungsTypenForPruefungQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPruefungsTypenForPruefungQuery({
 *   variables: {
 *      modulId: // value for 'modulId'
 *   },
 * });
 */
export function useGetPruefungsTypenForPruefungQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPruefungsTypenForPruefungQuery, GetPruefungsTypenForPruefungQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPruefungsTypenForPruefungQuery, GetPruefungsTypenForPruefungQueryVariables>(GetPruefungsTypenForPruefungDocument, baseOptions);
      }
export function useGetPruefungsTypenForPruefungLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPruefungsTypenForPruefungQuery, GetPruefungsTypenForPruefungQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPruefungsTypenForPruefungQuery, GetPruefungsTypenForPruefungQueryVariables>(GetPruefungsTypenForPruefungDocument, baseOptions);
        }
export type GetPruefungsTypenForPruefungQueryHookResult = ReturnType<typeof useGetPruefungsTypenForPruefungQuery>;
export type GetPruefungsTypenForPruefungLazyQueryHookResult = ReturnType<typeof useGetPruefungsTypenForPruefungLazyQuery>;
export type GetPruefungsTypenForPruefungQueryResult = ApolloReactCommon.QueryResult<GetPruefungsTypenForPruefungQuery, GetPruefungsTypenForPruefungQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
