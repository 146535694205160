import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetStudentSemesterStatusesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetStudentSemesterStatusesQuery = { studentSemesterStatuses: Array<{ __typename?: 'StudentSemesterStatus', id: number, studentSemesterStatusName?: Types.Maybe<string> }> };


export const GetStudentSemesterStatusesDocument = gql`
    query getStudentSemesterStatuses {
  studentSemesterStatuses {
    id
    studentSemesterStatusName
  }
}
    `;

/**
 * __useGetStudentSemesterStatusesQuery__
 *
 * To run a query within a React component, call `useGetStudentSemesterStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStudentSemesterStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStudentSemesterStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStudentSemesterStatusesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStudentSemesterStatusesQuery, GetStudentSemesterStatusesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetStudentSemesterStatusesQuery, GetStudentSemesterStatusesQueryVariables>(GetStudentSemesterStatusesDocument, baseOptions);
      }
export function useGetStudentSemesterStatusesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStudentSemesterStatusesQuery, GetStudentSemesterStatusesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetStudentSemesterStatusesQuery, GetStudentSemesterStatusesQueryVariables>(GetStudentSemesterStatusesDocument, baseOptions);
        }
export type GetStudentSemesterStatusesQueryHookResult = ReturnType<typeof useGetStudentSemesterStatusesQuery>;
export type GetStudentSemesterStatusesLazyQueryHookResult = ReturnType<typeof useGetStudentSemesterStatusesLazyQuery>;
export type GetStudentSemesterStatusesQueryResult = ApolloReactCommon.QueryResult<GetStudentSemesterStatusesQuery, GetStudentSemesterStatusesQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
