import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetPruefungsergebniseQueryVariables = Types.Exact<{
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  where?: Types.Maybe<Types.PruefungsergebniFilterInput>;
  order?: Types.Maybe<Array<Types.PruefungsergebniSortInput> | Types.PruefungsergebniSortInput>;
}>;


export type GetPruefungsergebniseQuery = { pruefungsergebnis: Array<{ __typename?: 'Pruefungsergebni', id: number, note?: Types.Maybe<number>, punkte?: Types.Maybe<number>, effektivDatum?: Types.Maybe<string>, studentId?: Types.Maybe<number>, bemerkung?: Types.Maybe<string>, pruefungStatusId?: Types.Maybe<number>, pruefung?: Types.Maybe<{ __typename?: 'Pruefung', maxPunktzahl: number, datumUhrzeitVon?: Types.Maybe<string>, id: number, pruefungsversuch?: Types.Maybe<number>, pruefungTyp?: Types.Maybe<{ __typename?: 'PruefungTyp', id: number, pruefungTypName?: Types.Maybe<string> }> }>, student?: Types.Maybe<{ __typename?: 'Student', anrede?: Types.Maybe<string>, nachname?: Types.Maybe<string>, vorname?: Types.Maybe<string> }>, pruefungStatus?: Types.Maybe<{ __typename?: 'PruefungStatus', id: number, pruefungStatusName?: Types.Maybe<string> }> }> };


export const GetPruefungsergebniseDocument = gql`
    query getPruefungsergebnise($skip: Int, $take: Int, $where: PruefungsergebniFilterInput, $order: [PruefungsergebniSortInput!]) {
  pruefungsergebnis(where: $where, order: $order, skip: $skip, take: $take) {
    id
    note
    punkte
    effektivDatum
    pruefung {
      maxPunktzahl
      datumUhrzeitVon
      id
      pruefungsversuch
      pruefungTyp {
        id
        pruefungTypName
      }
    }
    studentId
    student {
      anrede
      nachname
      vorname
    }
    bemerkung
    pruefungStatus {
      id
      pruefungStatusName
    }
    pruefungStatusId
  }
}
    `;

/**
 * __useGetPruefungsergebniseQuery__
 *
 * To run a query within a React component, call `useGetPruefungsergebniseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPruefungsergebniseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPruefungsergebniseQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      where: // value for 'where'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetPruefungsergebniseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPruefungsergebniseQuery, GetPruefungsergebniseQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPruefungsergebniseQuery, GetPruefungsergebniseQueryVariables>(GetPruefungsergebniseDocument, baseOptions);
      }
export function useGetPruefungsergebniseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPruefungsergebniseQuery, GetPruefungsergebniseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPruefungsergebniseQuery, GetPruefungsergebniseQueryVariables>(GetPruefungsergebniseDocument, baseOptions);
        }
export type GetPruefungsergebniseQueryHookResult = ReturnType<typeof useGetPruefungsergebniseQuery>;
export type GetPruefungsergebniseLazyQueryHookResult = ReturnType<typeof useGetPruefungsergebniseLazyQuery>;
export type GetPruefungsergebniseQueryResult = ApolloReactCommon.QueryResult<GetPruefungsergebniseQuery, GetPruefungsergebniseQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
