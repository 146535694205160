import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetMoodleModulsForPruefungCreateQueryVariables = Types.Exact<{
  id: Types.Scalars['Int'];
}>;


export type GetMoodleModulsForPruefungCreateQuery = { moodleModul?: Types.Maybe<{ __typename?: 'MoodleModul', id: number, modulId?: Types.Maybe<number>, zeitraumId?: Types.Maybe<number>, lehrender?: Types.Maybe<{ __typename?: 'StammdatenMa', id: number, anrede: string, vorname: string, nachname: string }>, modul?: Types.Maybe<{ __typename?: 'Modul', id: number, dauer?: Types.Maybe<number>, ects?: Types.Maybe<number>, pruefungsform?: Types.Maybe<{ __typename?: 'Pruefungsform', id: number, pruefungsformenName: string }> }> }> };


export const GetMoodleModulsForPruefungCreateDocument = gql`
    query getMoodleModulsForPruefungCreate($id: Int!) {
  moodleModul(id: $id) {
    id
    modulId
    zeitraumId
    lehrender {
      id
      anrede
      vorname
      nachname
    }
    modul {
      id
      dauer
      pruefungsform {
        id
        pruefungsformenName
      }
      ects: ectspunkte
    }
  }
}
    `;

/**
 * __useGetMoodleModulsForPruefungCreateQuery__
 *
 * To run a query within a React component, call `useGetMoodleModulsForPruefungCreateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMoodleModulsForPruefungCreateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMoodleModulsForPruefungCreateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMoodleModulsForPruefungCreateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMoodleModulsForPruefungCreateQuery, GetMoodleModulsForPruefungCreateQueryVariables>) {
        return ApolloReactHooks.useQuery<GetMoodleModulsForPruefungCreateQuery, GetMoodleModulsForPruefungCreateQueryVariables>(GetMoodleModulsForPruefungCreateDocument, baseOptions);
      }
export function useGetMoodleModulsForPruefungCreateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMoodleModulsForPruefungCreateQuery, GetMoodleModulsForPruefungCreateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetMoodleModulsForPruefungCreateQuery, GetMoodleModulsForPruefungCreateQueryVariables>(GetMoodleModulsForPruefungCreateDocument, baseOptions);
        }
export type GetMoodleModulsForPruefungCreateQueryHookResult = ReturnType<typeof useGetMoodleModulsForPruefungCreateQuery>;
export type GetMoodleModulsForPruefungCreateLazyQueryHookResult = ReturnType<typeof useGetMoodleModulsForPruefungCreateLazyQuery>;
export type GetMoodleModulsForPruefungCreateQueryResult = ApolloReactCommon.QueryResult<GetMoodleModulsForPruefungCreateQuery, GetMoodleModulsForPruefungCreateQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
