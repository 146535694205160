import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetVerlaufTnsQueryVariables = Types.Exact<{
  filterInput?: Types.Maybe<Types.VerlaufTnFilterInput>;
}>;


export type GetVerlaufTnsQuery = { verlaufTns: Array<{ __typename?: 'VerlaufTn', id: number, studentId: number, verlaufTypTnid: number, verlaufDatum: string, verlaufTypTn: { __typename?: 'VerlaufTypTn', id: number, verlaufTypName: string } }> };


export const GetVerlaufTnsDocument = gql`
    query getVerlaufTns($filterInput: VerlaufTnFilterInput) {
  verlaufTns(where: $filterInput) {
    id
    studentId
    verlaufTypTnid
    verlaufDatum
    verlaufTypTn {
      id
      verlaufTypName
    }
  }
}
    `;

/**
 * __useGetVerlaufTnsQuery__
 *
 * To run a query within a React component, call `useGetVerlaufTnsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVerlaufTnsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVerlaufTnsQuery({
 *   variables: {
 *      filterInput: // value for 'filterInput'
 *   },
 * });
 */
export function useGetVerlaufTnsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetVerlaufTnsQuery, GetVerlaufTnsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetVerlaufTnsQuery, GetVerlaufTnsQueryVariables>(GetVerlaufTnsDocument, baseOptions);
      }
export function useGetVerlaufTnsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetVerlaufTnsQuery, GetVerlaufTnsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetVerlaufTnsQuery, GetVerlaufTnsQueryVariables>(GetVerlaufTnsDocument, baseOptions);
        }
export type GetVerlaufTnsQueryHookResult = ReturnType<typeof useGetVerlaufTnsQuery>;
export type GetVerlaufTnsLazyQueryHookResult = ReturnType<typeof useGetVerlaufTnsLazyQuery>;
export type GetVerlaufTnsQueryResult = ApolloReactCommon.QueryResult<GetVerlaufTnsQuery, GetVerlaufTnsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
