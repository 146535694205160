/* eslint-disable @typescript-eslint/no-unsafe-call */
import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import ImageIcon from "@material-ui/icons/Image";
import PdfIcon from "@material-ui/icons/PictureAsPdf";
import FileIcon from "@material-ui/icons/AttachFile";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "components/lbc-toolkit/atomics/Button/DefaultButton";

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      paddingTop: 0,
      paddingBottom: 0,
      color: "#000",
      width: "95%",
      height: "3em",
      marginBottom: 10,
    },
    dialog: {
      "@media screen and (max-width: 700px)": {
        width: "80%",
      },
    },
    divider: {
      border: "1px solid #ff6d00",
      borderRadius: 10,
      backgroundColor: "white",
      width: "95%",
      "&:hover": {
        border: "1px solid #ff6d00",
        cursor: "default",
      },
    },
    deleteIcon: {
      color: "#787673",
      fontSize: "100%",
      marginLeft: "25%",
      marginRight: "15px",
      "&:hover": {
        backgroundColor: "initial",
        color: `#ff6d00`,
      },
    },
    typography: {
      maxWidth: "100%",
    },
    icon: {
      color: "#000",
      fontSize: "100%",
      marginLeft: "15px",
      // marginRight: "15%",
    },
    yesButton: {
      fontSize: 14,
      color: theme.colors.tWhite,
      backgroundColor: theme.colors.sunYellow,
      height: 35,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tWhite, backgroundColor: theme.colors.sunYellow },
      "@media screen and (max-width: 700px)": {
        maxWidth: "50px%",
      },
    },
    noButton: {
      fontSize: 14,
      color: theme.colors.tBlack,
      backgroundColor: "rgb(230, 229, 227)",
      height: 35,
      borderRadius: "unset",
      "&:hover": { color: theme.colors.tBlack, backgroundColor: "rgb(230, 229, 227)" },
    },
  })
);

export interface FileUploadProps {
  files: File[];
  loading?: boolean;
  onClickDelete: (file: File) => void;
}
export type FileListProps = Pick<FileUploadProps, "files" | "loading" | "onClickDelete">;
const FileList: React.FC<FileListProps> = (props) => {
  const classes = useStyles();
  const [fileToDelete, setFileToDelete] = useState<File | null>(null);

  const handleDeleteFile = () => {
    if (fileToDelete === null) return;
    props.onClickDelete(fileToDelete);
    setFileToDelete(null);
  };
  const cancelDeleteFile = () => {
    setFileToDelete(null);
  };

  if (props.files.length === 0) return null;
  return (
    <>
      <List>
        {props.files.map((file, index) => {
          const fileType = file.type;
          let icon = <FileIcon />;
          if (fileType) {
            if (fileType.includes("image")) {
              icon = <ImageIcon />;
            } else if (fileType.includes("pdf")) {
              icon = <PdfIcon />;
            }
          }
          return (
            <ListItem
              key={`${index}-${file.name}`}
              dense
              divider
              disableGutters
              classes={{
                dense: classes.listItem,
                divider: classes.divider,
              }}
            >
              <ListItemIcon className={classes.icon}>{icon}</ListItemIcon>
              <ListItemText>
                <Typography className={classes.typography} variant="body2">
                  {file.name}
                </Typography>
              </ListItemText>
              <ListItemIcon>
                <IconButton
                  className={classes.deleteIcon}
                  onClick={() => {
                    if (!props.loading) {
                      setFileToDelete(file);
                    }
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
      <Dialog open={!!fileToDelete} onClose={cancelDeleteFile} className={classes.dialog}>
        <DialogTitle>Datei löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>Sind Sie sicher, dass Sie die Datei löschen wollen?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cancelDeleteFile}
            buttonType="primary"
            label="Nein"
            style={{ width: "30%" }}
            classes={{ colorPrimary: classes.noButton }}
          />
          <Button
            onClick={handleDeleteFile}
            buttonType="primary"
            classes={{ colorPrimary: classes.yesButton }}
            autoFocus
            style={{ width: "30%" }}
            label="Ja"
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FileList;
