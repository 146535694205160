import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetNamesForIdsForHubSpotQueryVariables = Types.Exact<{
  zahlungsmodellId: Types.Scalars['Int'];
  geschlechtId: Types.Scalars['Int'];
  studiengangSemesterId: Types.Scalars['Int'];
}>;


export type GetNamesForIdsForHubSpotQuery = { zahlungsmodells: Array<{ __typename?: 'Zahlungsmodell', id: number, zahlungsmodellName: string }>, geschlechts: Array<{ __typename?: 'Geschlecht', geschlechtName: string, id: number }>, studiengangSemesters: Array<{ __typename?: 'StudiengangSemester', id: number, studiengangOnBewId?: Types.Maybe<number>, semester?: Types.Maybe<{ __typename?: 'Semester', id: number, semesterName?: Types.Maybe<string> }>, studiengangOnBew?: Types.Maybe<{ __typename?: 'StudiengangOnBew', id: number, studiengangName: string, studienmodell: { __typename?: 'Studienmodell', id: number, studienmodellName: string } }> }> };


export const GetNamesForIdsForHubSpotDocument = gql`
    query getNamesForIdsForHubSpot($zahlungsmodellId: Int!, $geschlechtId: Int!, $studiengangSemesterId: Int!) {
  zahlungsmodells(where: {zahlungsmodellId: {eq: $zahlungsmodellId}}) {
    id
    zahlungsmodellName
  }
  geschlechts(where: {geschlechtId: {eq: $geschlechtId}}) {
    geschlechtName
    id
  }
  studiengangSemesters(where: {studiengangSemesterId: {eq: $studiengangSemesterId}}) {
    id
    studiengangOnBewId
    semester {
      id
      semesterName
    }
    studiengangOnBew {
      studienmodell {
        id
        studienmodellName
      }
      id
      studiengangName
    }
  }
}
    `;

/**
 * __useGetNamesForIdsForHubSpotQuery__
 *
 * To run a query within a React component, call `useGetNamesForIdsForHubSpotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNamesForIdsForHubSpotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNamesForIdsForHubSpotQuery({
 *   variables: {
 *      zahlungsmodellId: // value for 'zahlungsmodellId'
 *      geschlechtId: // value for 'geschlechtId'
 *      studiengangSemesterId: // value for 'studiengangSemesterId'
 *   },
 * });
 */
export function useGetNamesForIdsForHubSpotQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNamesForIdsForHubSpotQuery, GetNamesForIdsForHubSpotQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNamesForIdsForHubSpotQuery, GetNamesForIdsForHubSpotQueryVariables>(GetNamesForIdsForHubSpotDocument, baseOptions);
      }
export function useGetNamesForIdsForHubSpotLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNamesForIdsForHubSpotQuery, GetNamesForIdsForHubSpotQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNamesForIdsForHubSpotQuery, GetNamesForIdsForHubSpotQueryVariables>(GetNamesForIdsForHubSpotDocument, baseOptions);
        }
export type GetNamesForIdsForHubSpotQueryHookResult = ReturnType<typeof useGetNamesForIdsForHubSpotQuery>;
export type GetNamesForIdsForHubSpotLazyQueryHookResult = ReturnType<typeof useGetNamesForIdsForHubSpotLazyQuery>;
export type GetNamesForIdsForHubSpotQueryResult = ApolloReactCommon.QueryResult<GetNamesForIdsForHubSpotQuery, GetNamesForIdsForHubSpotQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
