import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetListsForstudiengangSemestersSelectQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetListsForstudiengangSemestersSelectQuery = { studiengangSemesters: Array<{ __typename?: 'StudiengangSemester', id: number, semesterId?: Types.Maybe<number>, studiengangOnBewId?: Types.Maybe<number>, studiengangOnBew?: Types.Maybe<{ __typename?: 'StudiengangOnBew', id: number, studienmodellId: number, studiengangName: string, studienmodell: { __typename?: 'Studienmodell', id: number, studienmodellName: string } }>, semester?: Types.Maybe<{ __typename?: 'Semester', id: number, semesterName?: Types.Maybe<string>, studiengangSemesters: Array<{ __typename?: 'StudiengangSemester', id: number, studiengangOnBewId?: Types.Maybe<number> }> }> }> };


export const GetListsForstudiengangSemestersSelectDocument = gql`
    query getListsForstudiengangSemestersSelect {
  studiengangSemesters {
    id
    semesterId
    studiengangOnBewId
    studiengangOnBew {
      id
      studienmodellId
      studiengangName
      studienmodell {
        id
        studienmodellName
      }
    }
    semester {
      id
      semesterName
      studiengangSemesters {
        id
        studiengangOnBewId
      }
    }
  }
}
    `;

/**
 * __useGetListsForstudiengangSemestersSelectQuery__
 *
 * To run a query within a React component, call `useGetListsForstudiengangSemestersSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListsForstudiengangSemestersSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListsForstudiengangSemestersSelectQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetListsForstudiengangSemestersSelectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetListsForstudiengangSemestersSelectQuery, GetListsForstudiengangSemestersSelectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetListsForstudiengangSemestersSelectQuery, GetListsForstudiengangSemestersSelectQueryVariables>(GetListsForstudiengangSemestersSelectDocument, baseOptions);
      }
export function useGetListsForstudiengangSemestersSelectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetListsForstudiengangSemestersSelectQuery, GetListsForstudiengangSemestersSelectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetListsForstudiengangSemestersSelectQuery, GetListsForstudiengangSemestersSelectQueryVariables>(GetListsForstudiengangSemestersSelectDocument, baseOptions);
        }
export type GetListsForstudiengangSemestersSelectQueryHookResult = ReturnType<typeof useGetListsForstudiengangSemestersSelectQuery>;
export type GetListsForstudiengangSemestersSelectLazyQueryHookResult = ReturnType<typeof useGetListsForstudiengangSemestersSelectLazyQuery>;
export type GetListsForstudiengangSemestersSelectQueryResult = ApolloReactCommon.QueryResult<GetListsForstudiengangSemestersSelectQuery, GetListsForstudiengangSemestersSelectQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
