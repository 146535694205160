import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetModulsPrufungsTermineQueryVariables = Types.Exact<{
  where?: Types.Maybe<Types.MoodleModulFilterInput>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  order?: Types.Maybe<Array<Types.MoodleModulSortInput> | Types.MoodleModulSortInput>;
}>;


export type GetModulsPrufungsTermineQuery = { moodleModuls: Array<{ __typename?: 'MoodleModul', id: number, zeitraumId?: Types.Maybe<number>, pruefungs: Array<{ __typename?: 'Pruefung', id: number, datumUhrzeitVon?: Types.Maybe<string>, datumUhrzeitBis?: Types.Maybe<string> }>, modultermins: Array<{ __typename?: 'Modultermin', id: number, datumVon?: Types.Maybe<string>, datumBis?: Types.Maybe<string> }>, modul?: Types.Maybe<{ __typename?: 'Modul', id: number, modulName: string }>, modulVerantwortlicher?: Types.Maybe<{ __typename?: 'StammdatenMa', id: number, vorname: string, nachname: string }>, zeitraum?: Types.Maybe<{ __typename?: 'Zeitraum', id: number, zeitraumName: string, zeitraumVon: string, zeitraumBis: string }> }> };


export const GetModulsPrufungsTermineDocument = gql`
    query getModulsPrufungsTermine($where: MoodleModulFilterInput, $take: Int, $skip: Int, $order: [MoodleModulSortInput!]) {
  moodleModuls(where: $where, take: $take, skip: $skip, order: $order) {
    id
    pruefungs(where: {isDeleted: {neq: true}}) {
      id
      datumUhrzeitVon
      datumUhrzeitBis
    }
    modultermins(where: {isDeleted: {neq: true}}) {
      id
      datumVon
      datumBis
    }
    modul {
      id
      modulName
    }
    modulVerantwortlicher {
      id
      vorname
      nachname
    }
    zeitraumId
    zeitraum {
      id
      zeitraumName
      zeitraumVon
      zeitraumBis
    }
  }
}
    `;

/**
 * __useGetModulsPrufungsTermineQuery__
 *
 * To run a query within a React component, call `useGetModulsPrufungsTermineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModulsPrufungsTermineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModulsPrufungsTermineQuery({
 *   variables: {
 *      where: // value for 'where'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetModulsPrufungsTermineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetModulsPrufungsTermineQuery, GetModulsPrufungsTermineQueryVariables>) {
        return ApolloReactHooks.useQuery<GetModulsPrufungsTermineQuery, GetModulsPrufungsTermineQueryVariables>(GetModulsPrufungsTermineDocument, baseOptions);
      }
export function useGetModulsPrufungsTermineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetModulsPrufungsTermineQuery, GetModulsPrufungsTermineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetModulsPrufungsTermineQuery, GetModulsPrufungsTermineQueryVariables>(GetModulsPrufungsTermineDocument, baseOptions);
        }
export type GetModulsPrufungsTermineQueryHookResult = ReturnType<typeof useGetModulsPrufungsTermineQuery>;
export type GetModulsPrufungsTermineLazyQueryHookResult = ReturnType<typeof useGetModulsPrufungsTermineLazyQuery>;
export type GetModulsPrufungsTermineQueryResult = ApolloReactCommon.QueryResult<GetModulsPrufungsTermineQuery, GetModulsPrufungsTermineQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
