import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type GetPruefungTypsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetPruefungTypsQuery = { pruefungTyps: Array<{ __typename?: 'PruefungTyp', id: number, pruefungTypName?: Types.Maybe<string> }> };


export const GetPruefungTypsDocument = gql`
    query getPruefungTyps {
  pruefungTyps {
    id
    pruefungTypName
  }
}
    `;

/**
 * __useGetPruefungTypsQuery__
 *
 * To run a query within a React component, call `useGetPruefungTypsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPruefungTypsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPruefungTypsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPruefungTypsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPruefungTypsQuery, GetPruefungTypsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPruefungTypsQuery, GetPruefungTypsQueryVariables>(GetPruefungTypsDocument, baseOptions);
      }
export function useGetPruefungTypsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPruefungTypsQuery, GetPruefungTypsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPruefungTypsQuery, GetPruefungTypsQueryVariables>(GetPruefungTypsDocument, baseOptions);
        }
export type GetPruefungTypsQueryHookResult = ReturnType<typeof useGetPruefungTypsQuery>;
export type GetPruefungTypsLazyQueryHookResult = ReturnType<typeof useGetPruefungTypsLazyQuery>;
export type GetPruefungTypsQueryResult = ApolloReactCommon.QueryResult<GetPruefungTypsQuery, GetPruefungTypsQueryVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
