import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type ImportApplicantMutationVariables = Types.Exact<{
  bewerberIds: Array<Types.Scalars['Int']> | Types.Scalars['Int'];
}>;


export type ImportApplicantMutation = { importApplicant?: Types.Maybe<{ __typename?: 'ImportApplicants', status: boolean, importApplicants?: Types.Maybe<Array<Types.Maybe<{ __typename?: 'ImportApplicant', bewerberID: number, status: boolean, statusMessage?: Types.Maybe<string>, studentID?: Types.Maybe<number> }>>> }> };


export const ImportApplicantDocument = gql`
    mutation importApplicant($bewerberIds: [Int!]!) {
  importApplicant(bewerberIds: $bewerberIds) {
    status
    importApplicants {
      bewerberID
      status
      statusMessage
      studentID
    }
  }
}
    `;
export type ImportApplicantMutationFn = ApolloReactCommon.MutationFunction<ImportApplicantMutation, ImportApplicantMutationVariables>;

/**
 * __useImportApplicantMutation__
 *
 * To run a mutation, you first call `useImportApplicantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportApplicantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importApplicantMutation, { data, loading, error }] = useImportApplicantMutation({
 *   variables: {
 *      bewerberIds: // value for 'bewerberIds'
 *   },
 * });
 */
export function useImportApplicantMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ImportApplicantMutation, ImportApplicantMutationVariables>) {
        return ApolloReactHooks.useMutation<ImportApplicantMutation, ImportApplicantMutationVariables>(ImportApplicantDocument, baseOptions);
      }
export type ImportApplicantMutationHookResult = ReturnType<typeof useImportApplicantMutation>;
export type ImportApplicantMutationResult = ApolloReactCommon.MutationResult<ImportApplicantMutation>;
export type ImportApplicantMutationOptions = ApolloReactCommon.BaseMutationOptions<ImportApplicantMutation, ImportApplicantMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
