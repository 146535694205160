/* eslint-disable prettier/prettier */
// eslint-disable-next-line filenames/match-exported
import React, { useEffect, useState } from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { parseISO, formatISO } from "date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import Props, { RaftValueDateTime } from "./index.d";
import { formatDate } from "translations/formatter";
import DefaultInput from "components/atomics/DefaultInput";
export type DatePickerProps = Props;
const useStyles = makeStyles<Theme, Props>(
  (theme) =>
    createStyles({
      DatePickerRoot: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        margin: 10,
        "& .MuiIconButton-root": {
          color: (props: { disabled: boolean }) => (props.disabled ? theme.colors.grey300 : theme.colors.brandYellow),
        },
        "& .MuiInputBase-root.Mui-disabled": {
          cursor: "not-allowed",
          backgroundColor: theme.colors.tWhite,
        },
        "& :disabled": {
          cursor: "not-allowed",
        },
      },
      datePicker: {
        "&:hover": {
          backgroundColor: theme.colors.grey200,
          borderRadius: "6px",
          "& :disabled": {
            backgroundColor: theme.colors.tWhite,
          },
        },
      },
      titleBox: {
        minWidth: 80,
        marginRight: "5px",
      },
      title: {
        textAlign: "left",
      },
      error: {
        textAlign: "left",
      },
    }),
  { name: "Datepicker" }
);

const ensureInjectValue = (data: RaftValueDateTime[]) =>
  data.reduce((newItems: string[], item) => {
    if (item.state) {
      return newItems.concat(item.state);
    }
    return newItems;
  }, []);

const dateTimeToISO = (date: MaterialUiPickersDate | Date) => {
  let isoString = "";
  if (date) {
    try {
      isoString = `${formatISO((date as unknown) as Date).split("T")[0]}T03:17:16.873`;
    } catch (e) {
      console.log(e, date);
    }
  }
  return isoString;
};

const DatePicker: React.FC<Props> = (props) => {
  const {
    title,
    // @ts-ignore
    data: { state, setAndSave, setstate, setNull },
    datesAfter,
    datesBefore,
    minDate,
    maxDate,
    errors,
    disableFuture,
    disablePast,
    dateNullable,
    readonly,
    ...rest
  } = props;
  const [stateInner, setstateInner] = useState(state ? parseISO(state) : null);
  const [minDateInner, setMinDateInner] = useState<string | undefined>(undefined);
  const [maxDateInner, setMaxDateInner] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (datesBefore && datesBefore.length && !minDate) {
      setMinDateInner(ensureInjectValue(datesBefore).sort((a, b) => b.localeCompare(a))[0]);
    }
  }, [datesBefore]);
  useEffect(() => {
    if (datesAfter && datesAfter.length && !maxDate) {
      setMaxDateInner(ensureInjectValue(datesAfter).sort((a, b) => a.localeCompare(b))[0]);
    }
  }, [datesAfter]);
  // const n = datesAfter.map(ddd => ddd.state.)

  React.useEffect(() => {
    setstateInner(state ? parseISO(state) : null);
    return () => {};
  }, [state]);

  const setAndSaveInner = (date: MaterialUiPickersDate | Date) => {
    setAndSave(dateTimeToISO(date));
  };
  const handleChange = (date: MaterialUiPickersDate) => {
    if (date) {
      setstateInner((date as unknown) as Date);
    } else {
      if (dateNullable) {
        setstateInner(null);
        if (setNull) setNull();
      }
    }
  };
  const handleAccept = (date: MaterialUiPickersDate) => {
    setAndSaveInner(date);
  };

  const classes = useStyles(props);
  if (props.disabled)
    return (
      <DefaultInput
        className={classes.input}
        // onBlur={onBlur}
        value={stateInner ? formatDate(stateInner) : " "}
        // onChange={()=> {}}
        disabled={props.disabled}
        defaultValue=""
        error={!!errors?.length}
        label={title}
        helperText={errors?.concat(" ") ?? undefined}
        // {...rest}
      />
    );
  return (
    <KeyboardDatePicker
      error={!!errors?.length}
      label={title}
      // {...translation.date}
      variant="inline"
      inputVariant="outlined"
      // style={{ width: "15em" }}
      minDate={minDateInner}
      maxDate={maxDateInner}
      className={classes.datePicker}
      KeyboardButtonProps={{ style: { padding: 0 } }}
      // placeholder="  .  .   "
      // openTo="year"
      maxDateMessage={
        maxDateInner ? (
          <Typography className={classes.error} variant="body1">
            Das Datum sollte nicht nach dem {new Date(maxDateInner).toDateString()}
          </Typography>
        ) : undefined
      }
      minDateMessage={
        minDateInner ? (
          <Typography className={classes.error} variant="body1">
            Das Datum sollte nicht vor dem {new Date(minDateInner).toDateString()}
          </Typography>
        ) : undefined
      }
      autoOk
      // inputProps={{ readOnly: { readonly } }}
      value={stateInner}
      disabled={props.disabled}
      onBlur={() => (stateInner ? setAndSaveInner(stateInner) : null)}
      onAccept={handleAccept}
      onChange={handleChange}
      format="dd.MM.yyyy"
      views={["year", "month", "date"]}
      disableFuture={disableFuture}
      disablePast={disablePast}
      placeholder="  .  .   "
      openTo="year"
      InputAdornmentProps={{ position: "start" }}
      // views={["year", "month", "date"]}
      fullWidth
    />
  );
};

export default DatePicker;
