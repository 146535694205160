import type * as Types from '../../types';

import gql from 'graphql-tag';
import type * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/client';
export type DeleteUploadedDocumentMutationVariables = Types.Exact<{
  uploadFileId: Types.Scalars['Int'];
}>;


export type DeleteUploadedDocumentMutation = { deleteUploadedDocument?: Types.Maybe<{ __typename?: 'UploadedFile', id: number, downloadUri: string, fileNameBlob: string, fileTypeId: number, bewerberId: number, uploadDate: string, isDeleted: boolean, uploadFileType?: Types.Maybe<{ __typename?: 'UploadFileType', id: number }> }> };


export const DeleteUploadedDocumentDocument = gql`
    mutation deleteUploadedDocument($uploadFileId: Int!) {
  deleteUploadedDocument(uploadFileId: $uploadFileId) {
    id
    downloadUri
    fileNameBlob
    fileTypeId
    bewerberId
    uploadFileType {
      id
    }
    uploadDate
    isDeleted
  }
}
    `;
export type DeleteUploadedDocumentMutationFn = ApolloReactCommon.MutationFunction<DeleteUploadedDocumentMutation, DeleteUploadedDocumentMutationVariables>;

/**
 * __useDeleteUploadedDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteUploadedDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUploadedDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUploadedDocumentMutation, { data, loading, error }] = useDeleteUploadedDocumentMutation({
 *   variables: {
 *      uploadFileId: // value for 'uploadFileId'
 *   },
 * });
 */
export function useDeleteUploadedDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUploadedDocumentMutation, DeleteUploadedDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUploadedDocumentMutation, DeleteUploadedDocumentMutationVariables>(DeleteUploadedDocumentDocument, baseOptions);
      }
export type DeleteUploadedDocumentMutationHookResult = ReturnType<typeof useDeleteUploadedDocumentMutation>;
export type DeleteUploadedDocumentMutationResult = ApolloReactCommon.MutationResult<DeleteUploadedDocumentMutation>;
export type DeleteUploadedDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUploadedDocumentMutation, DeleteUploadedDocumentMutationVariables>;
export type PartialResultType<T> = Types.PartialResultType<T>;
export type ResultType<T> = Types.ResultType<T>;
export type NonMaybe<T> = Types.NonMaybe<T>;
